<template>
    <div class="container">
        <header>
            <router-link
                :to="{ name: 'profileIndex' }"
                v-slot="{ navigate }"
                custom
            >
                <div class="back-button" @click="navigate">
                    <i class="icon-leftarrow"></i>
                    返回
                </div>
            </router-link>
        </header>
        <router-link
            v-for="patient in patientList"
            :key="patient.id"
            :to="{ name: 'patientDetail', params: { id: patient.id } }"
            custom
            v-slot="{ href }"
        >
            <a class="record" :href="href">
                <span class="title">{{ patient.name }}</span>
                <i class="icon-rightarrow"></i>
            </a>
        </router-link>
    </div>
</template>

<script>
import { getPatientRecords } from "@/api";
export default {
    data: function () {
        return {
            patientList: [],
        };
    },
    created: function () {
        getPatientRecords().then((res) => {
            res.data.dataList.forEach((e) => {
                this.patientList.push({
                    id: e.id,
                    name: e.name,
                });
            });
            console.log(res);
        });
    },
};
</script>

<style lang="scss" scoped>
.container {
    padding: var(--safe-top) 0 var(--safe-bottom);
}
header {
    padding: 16px;
    @include flexr-between-center;
    font-size: 24px;
    font-weight: $semi-bold;
    color: $black;
}
.record {
    display: block;
    text-decoration: none;
    margin-bottom: 16px;
    background-color: $green-300;
    color: #fff;
    padding: 16px;
    @include flexr-between-center;
    .title {
        font-size: 21px;
        font-weight: $semi-bold;
    }
}
</style>